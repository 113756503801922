<template>
  <div class="labelcont column" :style="stilo">
    <div class="labelfont" style="text-align:left">
      {{ this.label }}
    </div>

    <div class="labeltxt">
      {{ this.txt }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: [String, Number], default: "" },
    txt: { type: [String, Number], default: "" },
    stilo: { type: String, default: "" }
  },

  data() {
    return {};
  }
};
</script>
<style scoped>
.labelcont {
  flex: 1 1 auto;
  border: none;
  border-radius: 18px;
  background-color: rgb(176, 190, 197, 0.35);
  height: 38px;
  margin-bottom: 4px;
}
.labelfont {
  font-size: 12px;
  color: steelblue;
  padding: 5px 10px 0 8px;
  /* padding-top:5px;
  padding-left:10px; */
  line-height: 0.9;
}
.labeltxt {
  font-size: 1rem;
  padding: 5px 10px 0 8px;
  line-height: 0.8;
  color: black;
}
</style>
